import React, {useEffect, useRef, useState} from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

interface MarkerOrderProps {
    position: [number, number];
    text: string;
}
const iconDefault = L.divIcon({
    className: 'custom-icon',
    html: `<div style="background: white; width: 200px; height: 100px"></div>`
});
const MarkerOrder: React.FC<MarkerOrderProps> = ({ position, text }) => {
    const [icon,setIcon] = useState<L.DivIcon>(iconDefault)
    const [idButton, setIdButton] = useState<string>('')
    useEffect(() => {
        const id = `button-${Math.floor(Math.random() * 10000).toString()}`
        const icon = L.divIcon({
            className: 'custom-icon',
            html: `<div class="icon-order">
                    <p class="fm f12">${text}</p>
                    <button class=" fm f12 detail" id="${id}">Детали</button>
                    </div>`
        });
        setIdButton(id)
        setIcon(icon)
    }, [text,position]);

    useEffect(() => {
        if (icon) {

            const button = document.getElementById(idButton);

            if (button) {
                button.addEventListener('click', handleButtonClick);
            }

            return () => {
                if (button) {
                    button.removeEventListener('click', handleButtonClick);
                }
            };
        }
    }, [icon]);

    const handleButtonClick = (e: MouseEvent) => {
        console.log(text);
        e.stopPropagation();
    }


    return (
        <Marker position={position} icon={icon} />
    );
};

export default MarkerOrder;