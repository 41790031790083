import {Tab, Tabs} from "react-bootstrap";
import styles from './style.module.scss'
import {FC, useEffect, useState} from "react";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {logout} from "@store/slices/userSlice";
import {IconButton} from "@common/components/IconButton/IconButton";
import ExitIcon from "@assets/icon/exit.svg";
import {User} from "@typesApp/userApi";
import {formatRole} from "@utils/formatRole";
import combiningClasses from "@utils/combiningClasses";
import {ListOrders} from "@pages/MapPage/components/ListOrders/ListOrders";
import {clearOrdersState} from "@store/slices/orderSlice";
import {clearMapState} from "@store/slices/mapSlice";
import {Role} from "@enums/role";
import {CreateOrder} from "@pages/MapPage/components/CreateOrder/CreateOrder";

interface PanelActionsProps {
    user: User | null,
}
export const PanelActions: FC<PanelActionsProps> = ({user}) => {
    const [activeTab,setActiveTab] = useState<string>('decoration')
    const dispatch = useAppDispatch()
    useEffect(() => {
        if(user?.role === Role.DRIVER){
            setActiveTab('orderHistory')
        }
    }, [user]);
    const selectTab = (key: string | null) => {
        setActiveTab(key as string)
    }
    const onLogout = () => {
        dispatch(logout())
        dispatch(clearOrdersState())
        dispatch(clearMapState())
    }
    return (
        <div className={styles.content}>
            <div className='d-flex justify-content-start align-items-center' style={{columnGap: 10}}>
                <p className={combiningClasses('fb','f16', styles.name)}>{user?.name}</p>
                <p className={combiningClasses('fb','f14', styles.role)}>{formatRole(user?.role)}</p>
                <div className={styles.exit}>
                    <IconButton onClick={onLogout} icon={ExitIcon}/>
                </div>
            </div>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={activeTab}
                    onSelect={selectTab}
                    className={styles.tabs}
                >
                    {
                        (() => {
                            switch (user?.role){
                                case Role.CLIENT:
                                case Role.ADMIN:
                                return (
                                    <Tab eventKey="decoration" title="Оформление заказа">
                                        <CreateOrder/>
                                    </Tab>
                                )
                                case Role.DRIVER:
                                    return null
                                default:
                                    return
                            }
                        })()
                    }
                    <Tab eventKey="orderHistory" title="История заказов">
                        <ListOrders/>
                    </Tab>
                </Tabs>
        </div>
    );
}