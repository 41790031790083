import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MapState, WindowState} from "@typesApp/stateApp";
import {LocationMarker} from "@typesApp/map";
import {WindowSize} from "@typesApp/window";

const initialState: WindowState =  {
    heightWindow: window.innerHeight,
    widthWindow: window.innerWidth
}
const windowSlice = createSlice(
    {
        name: 'window',
        initialState,
        reducers: {
            updateSizeWindow(state,action: PayloadAction<WindowSize>){
                state.heightWindow = action.payload.height
                state.widthWindow = action.payload.width
            }
        },
    }
)

export const {updateSizeWindow}  = windowSlice.actions
export default windowSlice.reducer