import {Offcanvas, Tab, Tabs} from "react-bootstrap";
import styles from './style.module.scss'
import {FC, useEffect, useState} from "react";
import {ButtonShow} from "@common/components/ButtonShow/ButtonShow";
import combiningClasses from "@utils/combiningClasses";
import {logout} from "@store/slices/userSlice";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {IconButton} from "@common/components/IconButton/IconButton";
import ExitIcon from '@assets/icon/exit.svg'
import {User} from "@typesApp/userApi";
import {formatRole} from "@utils/formatRole";
import {CreateOrder} from "@pages/MapPage/components/CreateOrder/CreateOrder";
import {ListOrders} from "@pages/MapPage/components/ListOrders/ListOrders";
import {Role} from "@enums/role";
interface PanelActionsProps {
    user: User | null,
    getMyLocation: () => void,
}
export const PanelActionsMobile: FC<PanelActionsProps> = ({user,getMyLocation}) => {
    const [show, setShow] = useState<boolean>(false);
    const [activeTab,setActiveTab] = useState<string>('decoration')
    const dispatch = useAppDispatch()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectTab = (key: string | null) => {
        setActiveTab(key as string)
    }
    const onLogout = () => {
        dispatch(logout())
    }
    useEffect(() => {
        if(user?.role === Role.DRIVER){
            setActiveTab('orderHistory')
        }
    }, [user]);
    return (
        <>
            <div className={combiningClasses(styles.wrap, styles.open)}>
                <ButtonShow onClick={handleShow} open={show}/>
            </div>

            <Offcanvas placement={'top'} show={show}>
                <Offcanvas.Body>
                    <div className='d-flex justify-content-start align-items-center' style={{columnGap: 5}}>
                        <p className={combiningClasses('fb', 'f16', styles.name)}>{user?.name}</p>
                        <p className={combiningClasses('fb', 'f12', styles.role)}>{formatRole(user?.role)}</p>
                        <div className={styles.exit}>
                            <IconButton onClick={onLogout} icon={ExitIcon}/>
                        </div>
                    </div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={activeTab}
                        onSelect={selectTab}
                        className={styles.tabs}
                    >
                        {
                            (() => {
                                switch (user?.role){
                                    case Role.CLIENT:
                                    case Role.ADMIN:
                                        return (
                                            <Tab eventKey="decoration" title="Оформление заказа">
                                                <CreateOrder/>
                                            </Tab>
                                        )
                                    case Role.DRIVER:
                                        return null
                                    default:
                                        return
                                }
                            })()
                        }
                        <Tab eventKey="orderHistory" title="История заказов">
                            <ListOrders/>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
                <div className={combiningClasses(styles.wrap,styles.close)}>
                    <ButtonShow onClick={handleClose} open={show}/>
                </div>
            </Offcanvas>
        </>
    );
}