import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MapState, OrderState} from "@typesApp/stateApp";
import {ActionModalOrder, TypeSelectOrder} from "@typesApp/order";
import {OrderApi} from "@api/OrderApi";
import {Notification} from "@utils/Notification/Notification";

const initialState: OrderState =  {
    types: [],
    statuses: [],
    orders: [],
    order: null,
    loadOrder: false,
    loadOrdersHistory: false
}

export const getTypesAndStatuses = createAsyncThunk(
    'order/getTypesAndStatuses',
    async (_,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.getStatusAndTypes()
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrders = createAsyncThunk(
    'order/getOrders',
    async (_,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.getOrders()
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrder = createAsyncThunk(
    'order/getOrder',
    async (id: number,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.getOrder(id)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
const orderSlice = createSlice(
    {
        name: 'order',
        initialState,
        reducers: {
            clearOrdersState(state){
                state.order = null
                state.types = []
                state.statuses = []
                state.orders = []
            },
            clearOrder(state){
                state.order = null
            }
        },
        extraReducers: (builder) => {
            builder.addCase(getTypesAndStatuses.fulfilled, (state,action) => {
                const types: TypeSelectOrder[]  = []
                const statuses: TypeSelectOrder[] = []
                for (const key in action.payload.types) {
                    types.push({
                        key,
                        value: action.payload.types[key]
                    })
                }

                for (const key in action.payload.statuses) {
                    statuses.push({
                        key,
                        value: action.payload.types[key]
                    })
                }
                state.statuses = statuses
                state.types = types
            })
            builder.addCase(getTypesAndStatuses.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrders.pending, (state) => {
                state.loadOrdersHistory = true
            })
            builder.addCase(getOrders.fulfilled, (state,action) => {
                state.orders = action.payload.data
                state.loadOrdersHistory = false
            })
            builder.addCase(getOrders.rejected, (state,action) => {
                const error: any = action.payload as any
                state.loadOrdersHistory = false
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrder.fulfilled, (state,action) => {
                state.order = action.payload.data
                state.loadOrder = false
            })
            builder.addCase(getOrder.pending, (state) => {
                state.loadOrder = true
            })
            builder.addCase(getOrder.rejected, (state,action) => {
                state.order = null
                state.loadOrder = false
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
        }
        
    }
)

export const { clearOrdersState,clearOrder }  = orderSlice.actions
export default orderSlice.reducer