import { useMap, } from "react-leaflet";
import  {LatLngExpression} from "leaflet";
import {FC} from "react";


interface ChangeStartLocationProps {
    center: LatLngExpression,
}
export const ChangeStartLocation: FC<ChangeStartLocationProps> = ({center}) => {
    const map = useMap()
    map.setView(center)
    return null;
}