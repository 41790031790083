import api from "@api/index";
import {ResOrder, ResOrders, ResTypeOrders} from "@typesApp/ordersApi";

export class OrderApi {
    public static getStatusAndTypes() {
        return api.get<ResTypeOrders>('/v1/data-order-enums')
    }
    public static getOrders() {
        return api.get<ResOrders>('/v1/orders')
    }
    public static getOrder(id: number) {
        return api.get<ResOrder>(`/v1/orders/${id}`)
    }
}