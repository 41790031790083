import styles from './style.module.scss';
import React, {FC, useEffect, useState} from "react";
import {LatLngExpression,} from "leaflet";
import {MapContainer, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {LocationMarker} from "@typesApp/map";
import {MyLocation} from "@pages/MapPage/components/map/MyLocation/MyLocation";
import {ChangeStartLocation} from "@pages/MapPage/components/map/ChangeStartLocation/ChangeStartLocation";
import {useAppSelector} from "@hooks/useAppSelector";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {MyLocationButton} from "@common/components/MyLocationButton/MyLocationButton";
import MarkerOrder from "@pages/MapPage/components/map/MarkerOrder/MarkerOrder";
import {Role} from "@enums/role";
import {updateUserAddress, updateUserMarker} from "@store/slices/mapSlice";
import {MapApi} from "@api/MapApi";
import {NO_ADDRESS} from "@const/map";

interface Gis2MapProps {
    userLocation: LocationMarker | null;
    getMyLocation: () => void
}

const defaultLocation: LatLngExpression = [55.9825, 37.1814]; // Зеленоград
const orders:any[] = [
    // {
    //     text: 'hello',
    //     location: [52.9825, 37.1804],
    // },
    // {
    //     text: 'Привет',
    //     location: [54.9025, 37.1814],
    // },
    // {
    //     text: 'Пока',
    //     location: [55.9925, 36.1814],
    // },
]
export const Gis2Map: FC<Gis2MapProps> = ({ userLocation ,getMyLocation}) => {
    const [centerLocation, setCenterLocation] = useState<LatLngExpression>(defaultLocation);
    const userAddress = useAppSelector(({map}) => map.userAddressLocation)
    const userMarkerPosition = useAppSelector(({map}) => map.userPositionMarker)
    const user = useAppSelector(({user}) => user.user)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const location: LatLngExpression = userLocation ?
            [userLocation.lat, userLocation.lot] : defaultLocation;
        changeMyLocation(location);
    }, [userLocation]);

    useEffect(() => {
        if(userMarkerPosition){
            setCenterLocation([userMarkerPosition.lat,userMarkerPosition.lot])
        }
    }, [userMarkerPosition]);
   const changeMyLocation = (value: LatLngExpression) => {
       if(user && (user.role === Role.ADMIN || user.role === Role.CLIENT)){
           const payload = value as number[]
           dispatch(updateUserMarker({
               lat: payload[0],
               lot: payload[1]
           }))
           MapApi.reverseGeocode(payload)
               .then(address => {
                   dispatch(updateUserAddress(address))
               })
               .catch(err => {
                   console.log(err)
                   dispatch(updateUserAddress(NO_ADDRESS))
               })
       }
   }
    return (
        <div id='map-container' className={styles.map}>

            <MapContainer
                center={centerLocation}
                zoom={13}
                style={{height: '100vh', width: '100%', zIndex: 12, position: 'relative'}}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; OpenStreetMap contributors"
                />
                <ChangeStartLocation center={centerLocation}/>
                <MyLocation changePosition={changeMyLocation} markerPosition={userMarkerPosition}/>
                {
                    user && (user.role === Role.DRIVER || user.role === Role.ADMIN) ?
                    orders.map((card,i) => (
                        <MarkerOrder key={i}  position={card.location} text={card.text}/>
                    )): null
                }
            </MapContainer>
            <div className={styles.action}>
                <MyLocationButton onClick={getMyLocation}/>
            </div>
        </div>
    );
};


