import {InputText} from "@common/components/InputText/InputText";
import {ChangeEvent, FC, useEffect, useState} from "react";
import {Info} from "@common/components/Info/Info";
import {COMMENT_HELP, NAME_HELP, TO_HELP, TYPE_HELP} from "@const/helpText";
import styles from './style.module.scss'
import {Text} from "@common/components/Text/Text";
import {Select} from "@common/components/Select/Select";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import {LocationMarker} from "@typesApp/map";
import {useAppSelector} from "@hooks/useAppSelector";
import {MyLocationButton} from "@common/components/MyLocationButton/MyLocationButton";
import {TypeSelectOrder} from "@typesApp/order";
import {ListOrders} from "@pages/MapPage/components/ListOrders/ListOrders";
import combiningClasses from "@utils/combiningClasses";
import {OrderApi} from "@api/OrderApi";
import {MapApi} from "@api/MapApi";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {updateUserAddress, updateUserMarker} from "@store/slices/mapSlice";
import {Notification} from "@utils/Notification/Notification";

interface CreateOrderProps{
    getMyLocation?: () => void
}
export const CreateOrder: FC<CreateOrderProps> = ({getMyLocation}) => {
    const [to,setTo] = useState<string>('')
    const [comment,setComment] = useState<string>('')
    const [typeOrder,setTypeOrder] = useState<TypeSelectOrder | null >( null )
    const [ToXY,setToXY] = useState<LocationMarker| null>(null)
    const locationXY = useAppSelector(({map}) => map.userPositionMarker)
    const locationAddress = useAppSelector(({map}) => map.userAddressLocation)
    const widthWindow = useAppSelector(({window}) => window.widthWindow)
    const typesOrder = useAppSelector(({order}) => order.types)
    const [isTemplate, setIsTemplate] = useState<boolean>(false)
    const [idTemplate, setIdTemplate] = useState<number>(0)
    const [noTemplate, setNoTemplate] = useState<boolean>(false)
    const ordersHistory = useAppSelector(({order}) => order.orders)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if(locationXY && locationAddress) {
            setToXY(locationXY)
            setTo(locationAddress)
        }
    }, [locationXY,locationAddress]);
    useEffect(() => {
        setTypeOrder(typesOrder[0])
    }, [typesOrder]);
    useEffect(() => {
        if(isTemplate && idTemplate){
            OrderApi.getOrder(idTemplate)
                .then(({data}) => {
                    const orderTemplate = data.data
                    if(orderTemplate.client_comment){
                        setComment(orderTemplate.client_comment)
                    } else {
                        setComment('')
                    }

                    const type  = typesOrder.find(el => orderTemplate.type)
                    if(type){
                        setTypeOrder({
                            key: type.key,
                            value: type.value
                        })
                    } else {
                        setTypeOrder(typesOrder[0])
                    }
                    if(orderTemplate.location_start){
                        const location: LocationMarker = {
                            lat: orderTemplate.location_start.lat,
                            lot: orderTemplate.location_start.lot
                        }
                        MapApi.reverseGeocode([location.lat,location.lot])
                            .then((address) => {
                                setToXY(location)
                                dispatch(updateUserMarker(location))
                                dispatch(updateUserAddress(address))
                            })
                            .catch(e => e)
                    }
                })
                .catch(err => {
                    Notification.error('Не удалось получить данные шаблона')
                })
            setIsTemplate(false)
            setNoTemplate(false)
            setIdTemplate(0)
        }
    }, [idTemplate]);
    const changeTo = (e: ChangeEvent) => {
        // const value: string = (e.target as HTMLInputElement).value
        // setTo(value)
    }
    const changeComment = (e: ChangeEvent) => {
        const value: string = (e.target as HTMLTextAreaElement).value
        setComment(value)
    }
    const changeTypeOrder = (type: TypeSelectOrder) => {
        setTypeOrder(type)
    }
    const onClick = () => {
        getMyLocation && getMyLocation()
    }
    const onCreate = () => {
        if(to && locationXY && typeOrder){
            const data = {
                location: {
                    address: to,
                    lot: locationXY?.lot,
                    lat: locationXY?.lat
                },
                type: typeOrder,
                comment: comment
            }
            console.log(data)
        }
    }
    const openSelectTemplate = () => {
        if(ordersHistory.length){
            setIsTemplate(true)
            setNoTemplate(false)
        } else {
            setNoTemplate(true)
            setIsTemplate(false)
        }
    }
    const closeSelect = () => {
        setIsTemplate(false)
        setNoTemplate(false)
        setIdTemplate(0)
    }
    const onSelectTemplate = (id: number) => {
        setIdTemplate(id)
    }
    return (
        <div className={styles.create}>
            {
                !isTemplate ? (
                    <>
                        <div className={styles.head}>
                            <p className='fb f20'>Создание заказа</p>
                            <div style={{position: 'relative'}}>
                                <button onClick={openSelectTemplate} className={styles.template}>Использовать шаблон
                                </button>
                                {noTemplate ? <p className={combiningClasses('fr')} style={{
                                    color: '#b0adad',
                                    position: 'absolute',
                                    fontSize: '12px',
                                    left: '6px',
                                    top: '20px',}}>Нет шаблонов</p>: null}
                            </div>
                        </div>
                        <div className={styles.form}>
                        <div className={styles.input}>
                                {
                                    widthWindow <= 890 ? (
                                        <div style={{marginTop: 2}}>
                                            <MyLocationButton onClick={onClick}/>
                                        </div>
                                    ) : null
                                }
                                <InputText disabled width='100%' onChange={changeTo} placeholder='Местоположение'
                                           value={to}/>
                                <Info content={TO_HELP}/>
                            </div>
                            <div className={styles.input}>
                                <Select width='150px' value={typeOrder} onSelect={changeTypeOrder} list={typesOrder}/>
                                <Info content={TYPE_HELP}/>
                            </div>
                            <div className={styles.input}>
                                <Text width='100%' onChange={changeComment} placeholder='Комментарий к заказу'
                                      value={comment}/>
                                <Info content={COMMENT_HELP}/>
                            </div>
                            <SubmitButton text={'Создать'} onClick={onCreate}/>
                        </div>
                    </>
                ) : (
                    <>
                        <p className='fm f16'>Выбирите шаблон для заполнения формы</p>
                        <button style={{
                            padding: 0,
                            marginLeft: 16,
                        }} onClick={closeSelect} className={styles.template}>Ничего не выбирать</button>
                        <ListOrders select={isTemplate} onSelect={onSelectTemplate}/>
                    </>
                )
            }
        </div>
    )
}