import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserState} from "@typesApp/stateApp";
import {typeForm} from "@enums/auth";
import {
    ForgetPasswordReq,
    LoginReq,
    OnboardingReq,
    ReauthorizationReq,
    RefreshPasswordReq,
    RefreshSendCodeReq,
    RegisterReq
} from "@typesApp/userApi";
import {AuthApi} from "@api/AuthApi";
import {Notification} from "@utils/Notification/Notification";
import {UserApi} from "@api/UserApi";
import {Role} from "@enums/role";

export const registerStep1 = createAsyncThunk(
    'user/registerStep1',
    async (payload: OnboardingReq,{rejectWithValue}) => {
        try {
            const {data} =  await AuthApi.registerStep1(payload)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const registerStep2 = createAsyncThunk(
    'user/registerStep2',
    async (payload: RegisterReq,{rejectWithValue}) => {
        try {
            const {data} = await AuthApi.registerStep2(payload)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const login = createAsyncThunk(
    'user/login',
    async (payload: LoginReq,{rejectWithValue}) => {
        try {
            const {data} = await AuthApi.login(payload)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const logout = createAsyncThunk(
    'user/logout',
    async (_,{rejectWithValue}) => {
        try {
            await AuthApi.logout()
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const reauthorization = createAsyncThunk(
    'user/reauthorization',
    async (payload: ReauthorizationReq,{rejectWithValue}) => {
        try {
            const {data} =  await AuthApi.reauthorization(payload)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const forgetPassword = createAsyncThunk(
    'user/forgetPassword',
    async (payload: ForgetPasswordReq,{rejectWithValue}) => {
        try {
            const {data} =  await AuthApi.forgetPassword(payload)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const refreshPassword = createAsyncThunk(
    'user/refreshPassword',
    async (payload: RefreshPasswordReq,{rejectWithValue}) => {
        try {
            const {data} =  await AuthApi.refreshPassword(payload)
            return data
        }catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const refreshSendCode = createAsyncThunk(
    'user/refreshSendCode',
    async (payload: RefreshSendCodeReq,{rejectWithValue}) => {
        try {
            const {data} =  await AuthApi.refreshSendCode(payload)
            return data
        }catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const onboardingSendCode = createAsyncThunk(
    'user/onboardingSendCode',
    async (payload: RefreshSendCodeReq,{rejectWithValue}) => {
        try {
            const {data} =  await AuthApi.onboardingSendCode(payload)
            return data
        }catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getUser = createAsyncThunk(
    'user/getUser',
    async (_,{rejectWithValue}) => {
        try {
            const {data} =  await UserApi.getUser()
            return data
        }catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
const initialState: UserState =  {
    typeForm: typeForm.LOGIN,
    auth: false,
    user: null,
    modalEnterCode: false,
}
const userSlice = createSlice(
    {
        name: 'user',
        initialState,
        reducers: {
            updateTypeForm(state, action: PayloadAction<typeForm>) {
                state.typeForm = action.payload
            },
            updateAuth(state, action: PayloadAction<boolean>) {
                state.auth = action.payload
            },
            updateModalEnterCode(state, action: PayloadAction<boolean>) {
                state.modalEnterCode = action.payload
            },
        },
        extraReducers: (builder) =>{
            builder.addCase(registerStep1.fulfilled,(state,action) => {
                state.user = action.payload.user
                state.modalEnterCode = true
            })
            builder.addCase(registerStep1.rejected,(state,action) => {
                state.user = null
                state.modalEnterCode = false
                const error: any = action.payload as any
                if(error?.errors?.phone[0]){
                    Notification.error(error?.errors?.phone[0])
                } else {
                    Notification.error("Ошибка сервера")
                }
            })
            builder.addCase(registerStep2.fulfilled,(state,action) => {
                localStorage.setItem('access_token',JSON.stringify(action.payload.accessToken))
                localStorage.setItem('refresh_token',JSON.stringify(action.payload.refreshToken))
                localStorage.setItem('access_token_expires',JSON.stringify(action.payload.accessTokenExpires))
                if(state.user){
                    state.auth = true
                }
                state.modalEnterCode = false
            })
            builder.addCase(registerStep2.rejected,(state,action) => {
                const error: any = action.payload as any

                if(error?.errors?.phone_code[0]){
                    Notification.error(error?.errors['phone_code'][0])
                } else if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(login.fulfilled,(state,action) => {
                state.user = action.payload.user
                localStorage.setItem('access_token',JSON.stringify(action.payload.accessToken))
                localStorage.setItem('refresh_token',JSON.stringify(action.payload.refreshToken))
                localStorage.setItem('access_token_expires',JSON.stringify(action.payload.accessTokenExpires))
                state.auth = true
            })
            builder.addCase(login.rejected,(state,action) => {
                state.user = null
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(reauthorization.fulfilled,(state,action) => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('access_token_expires')
                localStorage.setItem('access_token',JSON.stringify(action.payload.accessToken))
                localStorage.setItem('refresh_token',JSON.stringify(action.payload.refreshToken))
                localStorage.setItem('access_token_expires',JSON.stringify(action.payload.accessTokenExpires))
                state.auth = true
            })
            builder.addCase(reauthorization.rejected,(state,action) => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('access_token_expires')
                state.user = null
                state.auth = false
            })
            builder.addCase(forgetPassword.fulfilled,(state,action) => {
                state.user = action.payload.user
                state.modalEnterCode = true
            })
            builder.addCase(forgetPassword.rejected,(state,action) => {
                state.user = null
                const error: any = action.payload as any

                if(error?.errors?.phone[0]){
                    Notification.error(error?.errors?.phone[0])
                } else if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(refreshPassword.fulfilled,(state,action) => {
                state.user = null
                state.typeForm = typeForm.LOGIN
                state.modalEnterCode = false
            })
            builder.addCase(refreshPassword.rejected,(state,action) => {
                state.typeForm = typeForm.RESET
                const error: any = action.payload as any
                if(error?.errors?.phone_code[0]){
                    Notification.error(error?.errors['phone_code'][0])
                } else if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(logout.fulfilled,(state) => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('access_token_expires')
                state.user = null
                state.auth = false
                state.typeForm = typeForm.LOGIN
            })
            builder.addCase(logout.rejected,(state,action) => {
                state.typeForm = typeForm.LOGIN
            })
            builder.addCase(refreshSendCode.fulfilled,(state,action) => {
                state.user = {
                    ...action.payload.user
                }
                Notification.success('SMS c кодом отправлен')
            })
            builder.addCase(refreshSendCode.rejected,(state,action) => {
                state.typeForm = typeForm.RESET
                const error: any = action.payload as any
                if(error?.errors?.phone_code[0]){
                    Notification.error(error?.errors['phone_code'][0])
                } else if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(onboardingSendCode.fulfilled,(state,action) => {
                state.user = {
                    ...action.payload.user
                }
                Notification.success('SMS c кодом отправлен')
            })
            builder.addCase(onboardingSendCode.rejected,(state,action) => {
                state.typeForm = typeForm.REGISTER
                const error: any = action.payload as any
                if (error?.errors?.phone_code[0]) {
                    Notification.error(error?.errors['phone_code'][0])
                } else if (error?.message) {
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getUser.fulfilled,(state,action) => {
                state.user = action.payload.user
                state.user.role = Role.DRIVER
            })
            builder.addCase(getUser.rejected,(state,action) => {
                state.user = null
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
        }
    }
)

export const { updateTypeForm, updateModalEnterCode,updateAuth}  = userSlice.actions
export default userSlice.reducer