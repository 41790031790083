import styles from './style.module.scss'
import {Gis2Map} from "@pages/MapPage/components/Gis2Map/Gis2Map";
import {useAppSelector} from "@hooks/useAppSelector";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {updateUserAddress, updateUserLocation, updateUserMarker} from "@store/slices/mapSlice";
import { useEffect} from "react";
import {PanelActions} from "@pages/MapPage/components/PanelActions/PanelActions";
import {PanelActionsMobile} from "@pages/MapPage/components/PanelActionsMobile/PanelActionsMobile";
import {getUser} from "@store/slices/userSlice";
import {MapApi} from "@api/MapApi";
import {NO_ADDRESS} from "@const/map";
import {getOrders, getTypesAndStatuses} from "@store/slices/orderSlice";

export const MapPage = () => {
    const user = useAppSelector(({user}) => user.user)
    const userLocation = useAppSelector(({map}) => map.userLocation)
    const dispatch = useAppDispatch()
    const widthWindow = useAppSelector(({window}) => window.widthWindow)
    useEffect(()  => {
        getLocation()
    },[])
    useEffect(() => {
        if(!user) dispatch(getUser())
    }, []);
    useEffect(() => {
        dispatch(getTypesAndStatuses())
    }, []);
    useEffect(() => {
        dispatch(getOrders())
    }, []);
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch(updateUserLocation({
                        lat: latitude,
                        lot: longitude
                    }))
                },
                (error) => {
                    console.log(error.message)
                    dispatch(updateUserLocation(null))

                }
            );
        } else {
            dispatch(updateUserLocation(null))
        }
    };
    const getLocationAndAddress = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch(updateUserLocation({
                        lat: latitude,
                        lot: longitude
                    }))
                    dispatch(updateUserMarker({
                        lat: latitude,
                        lot: longitude
                    }))
                    MapApi.reverseGeocode([latitude,longitude])
                        .then(address => {
                            dispatch(updateUserAddress(address))
                        })
                        .catch(err => {
                            console.log(err)
                            dispatch(updateUserAddress(NO_ADDRESS))
                        })
                },
                (error) => {
                    console.log(error.message)
                    dispatch(updateUserLocation(null))

                }
            );
        } else {
            dispatch(updateUserLocation(null))
        }
    }
    if(widthWindow > 890) {
        return (
            <div className={styles.page}>
                <div className={styles.panel}><PanelActions user={user}/></div>
                <div className={styles.map}><Gis2Map getMyLocation={getLocationAndAddress} userLocation={userLocation}/></div>
            </div>
        )
    } else {
        return (
            <div className={styles.pageMobile}>
                <PanelActionsMobile getMyLocation={getLocationAndAddress} user={user}/>
                <Gis2Map getMyLocation={getLocationAndAddress} userLocation={userLocation}/>
            </div>
        )
    }

}